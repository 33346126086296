import React from "react";

import OfficesList from "../Component/OfficesList";

export default function OfficesListPages() {
  return (
    <>
      <div>
        <OfficesList />
      </div>
    </>
  );
}
