import React from "react";

import DistrictList from "../Component/DistrictCard";

export default function MemberListPage() {
  return (
    <>
      <div>
        <DistrictList />
      </div>
    </>
  );
}
