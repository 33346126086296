import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  Skeleton,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TablePagination,
  Grid,
} from "@mui/material";

import { apiUrl } from "../constants";
import Auth from "../Authentication";

export default function Circular() {
  const { token } = Auth();

  let config = {
    headers: {
      Authorization: token,
    },
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [data, setData] = useState([]);

  const skeletonArray = Array.from({ length: 5 }, (_, index) => (
    <Skeleton key={index} variant="wave" width={"100%"} height={40} />
  ));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchResolution = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${apiUrl}/godda_get_prc_by_type?type=1st prc`,
        config
      );
      setData(res.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchNotice = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${apiUrl}/godda_get_prc_by_type?type=3rd prc`,
        config
      );
      setData(res.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchActs = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${apiUrl}/godda_get_prc_by_type?type=2nd prc`,
        config
      );
      setData(res.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchCircular = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${apiUrl}/godda_get_prc_by_type?type=4th prc`,
        config
      );
      setData(res.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchCircular1 = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${apiUrl}/godda_get_prc_by_type?type=5th prc`,
        config
      );
      setData(res.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchCircular2 = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${apiUrl}/godda_get_prc_by_type?type=6th prc`,
        config
      );
      setData(res.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchCircular3 = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${apiUrl}/godda_get_prc_by_type?type=7th prc`,
        config
      );
      setData(res.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="px-4 md:px-10 py-4">
      <Grid container spacing={3} justifyContent="center">
        {[
          "1st PRC",
          "2nd PRC",
          "3rd PRC",
          "4th PRC",
          "5th PRC",
          "6th PRC",
          "7th PRC",
        ].map((label, index) => {
          const fetchFunctions = [
            fetchResolution,
            fetchActs,
            fetchNotice,
            fetchCircular,
            fetchCircular1,
            fetchCircular2,
            fetchCircular3,
          ];
          return (
            <Grid item xs={6} sm={4} md={2} key={index}>
              <Button
                fullWidth
                variant="contained"
                color="success"
                onClick={() => {
                  fetchFunctions[index]();
                  setText(label);
                }}
              >
                {label}
              </Button>
            </Grid>
          );
        })}
      </Grid>

      <br />

      <div>
        <h2
          className="text-xl font-bold mb-4"
          style={{ color: "blue", textAlign: "center" }}
        >
          {text}
        </h2>
        {loading ? (
          skeletonArray
        ) : (
          <>
            {text !== "" ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Letter No.
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Applicable Period
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>DA Rate</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Remark</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.date}</TableCell>
                          <TableCell>{item.letterNo}</TableCell>
                          <TableCell>{item.applicablePeriod}</TableCell>
                          <TableCell>{item.daRate}</TableCell>
                          <TableCell>{item.remarks}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={data.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            ) : (
              <h3 style={{ textAlign: "center", color: "red" }}>
                Please select a PRC to view the data!
              </h3>
            )}
          </>
        )}
      </div>
    </div>
  );
}
