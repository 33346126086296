import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
// import Swal from "sweetalert2";
// import { accessToken } from "../Authentication";
import { accessToken } from "../service/variables";

import { apiUrl } from "../constants";
import Auth from "../Authentication";
import { Box } from "@mui/material";

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";

const WordDictionaryList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dictionaryData, setDictionaryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://vkfpe87plb.execute-api.ap-south-1.amazonaws.com/production/get_all_words_from_dictionary?sortKey=word`,
          {
            headers: {
              Authorization: accessToken(),
            },
          }
        );
        console.log("Dictionary data", response);
        setDictionaryData(response.data.items);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching dictionary data:", error);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchKeyChange = (event) => {
    setSearchKey(event.target.value);
  };

  //   const handleDelete = async (wordId) => {
  //     try {
  //       if (!wordId) {
  //         Swal.fire("Error", "The record ID is missing. Please try again.", "error");
  //         return;
  //       }

  //       // Delete record using delete API
  //       await axios.delete(`https://vkfpe87plb.execute-api.ap-south-1.amazonaws.com/production/delete_word_from_dictionary`, {
  //         data: { id: wordId },
  //         headers: {
  //           Authorization: accessToken(),
  //         },
  //       });

  //       // Remove the deleted record from the state
  //       setDictionaryData((prevData) => prevData.filter((word) => word.id !== wordId));

  //       Swal.fire("Deleted!", "The word has been deleted.", "success");
  //     } catch (error) {
  //       console.error("Error deleting word:", error);
  //       Swal.fire("Error", "An error occurred while deleting the word.", "error");
  //     }
  //   };
  const handleDelete = async (wordId) => {
    try {
      if (!wordId) {
        // Swal.fire(
        //   "Error",
        //   "The record ID is missing. Please try again.",
        //   "error"
        // );
        return;
      }

      // Delete record using delete API with path param
      const response = await axios.delete(
        `https://vkfpe87plb.execute-api.ap-south-1.amazonaws.com/production/delete_word_from_dictionary?id=${wordId}`,
        {
          headers: {
            Authorization: accessToken(),
          },
        }
      );

      if (response.status === 200) {
        // Remove the deleted record from the state
        setDictionaryData((prevData) =>
          prevData.filter((word) => word.id !== wordId)
        );
        // Swal.fire("Deleted!", "The word has been deleted.", "success");
      } else {
        // Swal.fire(
        //   "Error",
        //   response.data.message || "Failed to delete the word.",
        //   "error"
        // );
      }
    } catch (error) {
      console.error("Error deleting word:", error);
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while deleting the word.";
      // Swal.fire("Error", errorMessage, "error");
    }
  };

  const filteredData = dictionaryData.filter((word) =>
    word.word.toLowerCase().includes(searchKey.toLowerCase())
  );

  return (
    <div>
      <Box mb={2}>
        <TextField
          label="Search by Word"
          variant="outlined"
          fullWidth
          value={searchKey}
          onChange={handleSearchKeyChange}
          placeholder="Search for a word"
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <Box component="thead">
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                S.N
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                Word
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                Meaning(Hindi/English/Urdu)
              </TableCell>

              {/* <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                Actions
              </TableCell> */}
            </TableRow>
          </Box>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((word, index) => (
                  <TableRow key={word.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{word.word}</TableCell>
                    <TableCell>{word.meaning}</TableCell>

                    {/* <TableCell>
                      <Button
                        variant="contained"
                        color="info"
                        onClick={() => handleDelete(word.id)}
                      >
                        Delete
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default WordDictionaryList;
