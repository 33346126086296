import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
  styled,
} from "@mui/material";
import axios from "axios";

import { accessToken } from "../service/variables";

const useStyles = styled((theme) => ({
  container: {
    margin: "20px auto",
    maxWidth: "90%",
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
    },
  },
  table: {
    minWidth: 650,
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
}));

const LeadershipTableList = () => {
  const classes = useStyles();

  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://vkfpe87plb.execute-api.ap-south-1.amazonaws.com/production/employee_by_verification_status",
          {
            isVerified: true, // Fetch all employees initially
          },
          {
            headers: {
              Authorization: accessToken(),
            },
          }
        );
        setEmployeeData(response.data["body-json"].body);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ margin: 15 }}>
      {loading && <CircularProgress size={24} />}
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold", fontSize: 20 }}>
                Member Id
              </TableCell>
              <TableCell style={{ fontWeight: "bold", fontSize: 20 }}>
                Name
              </TableCell>

              <TableCell style={{ fontWeight: "bold", fontSize: 20 }}>
                Office Name
              </TableCell>
              <TableCell style={{ fontWeight: "bold", fontSize: 20 }}>
                Employee Type
              </TableCell>

              <TableCell style={{ fontWeight: "bold", fontSize: 20 }}>
                Email
              </TableCell>
              <TableCell style={{ fontWeight: "bold", fontSize: 20 }}>
                Contact Number
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.employeeId}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.officeName}</TableCell>

                  <TableCell>{item.employeeType}</TableCell>
                  <TableCell>{item.email_id}</TableCell>
                  <TableCell>{item.contactNumber}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={employeeData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default LeadershipTableList;
